import '../styles/shapyaMaxWidth.css';
import '../styles/shapyaFonts.css';
import '../styles/shapyaColors.css';
import './ourStoresStyles.css';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  Grid,
} from '@material-ui/core';

import { Link } from 'gatsby';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '80px 10px',
  },
  card: {
    maxWidth: '362px',
    margin: theme.spacing(2),
  },
  cardActionArea: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '183px',
  },
  imgCard: {
    maxHeight: '170px',
  },
  storeName: {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },
  cardButton: {
    fontFamily: 'Montserrat',
    border: 'solid 2.3px #c43c42',
    borderRadius: '25px',
    color: '#c43c42',
    fontWeight: '700',
    textTransform: 'none',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  titleOurStores: {
    fontSize: '60px',
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },
  boxCard: {
    maxWidth: '362px',
  },
  '@media (max-width: 599px)': {
    root: {
      flexGrow: 1,
      padding: '40px 10px',
    },
    titleOurStores: {
      fontSize: '35px',
      fontWeight: '700',
      fontFamily: 'Montserrat',
    },
  },
}));

const OurStoresBody = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const branchStoresMapData = props.branchStoresData;

  return (
    <div className="shapyaMaxWidth">
      <div className={classes.root}>
        <Grid xs={12}>
          <Box className="shapyaText shapyaMediumRed" align="center" pb={5}>
            <h1 className={classes.titleOurStores}>{t('ourstores.title')}</h1>
          </Box>
        </Grid>
        <br />
        <Grid xs={12}>
          <Grid container spacing={3}>
            {branchStoresMapData.map((branchStore) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box align="center">
                  <Box className={classes.boxCard}>
                    <Card className={classes.card} elevation={0}>
                      <CardActionArea
                        className={classes.cardActionArea}
                        component={Link}
                        to={branchStore.node.oloUrl}
                      >
                        <img
                          src={branchStore.node.store.logo}
                          alt={branchStore.node.store.name}
                          title={branchStore.node.store.name}
                          className={classes.imgCard}
                        />
                      </CardActionArea>
                      <CardActions>
                        <p className={classes.storeName}>
                          {branchStore.node.store.name}
                        </p>
                      </CardActions>
                      <CardActions>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.cardButton}
                          disableElevation
                          component={Link}
                          to={branchStore.node.oloUrl}
                        >
                          {t('ourstores.seemore')}
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OurStoresBody;
